export const usersData = [
    {
        slug: "dr-hany-farag",
        title: "Dr. Hany Farag, MD MBA - Primary Care Physician at Fanous Clinic in Charlotte, NC",
        description: "",
        keywords: "",
        imageUrl: "https://fanousclinicmain.blob.core.windows.net/website-images-icons/dr-hany-farag-primary-care-physician.webp?sp=r&st=2024-09-01T11:28:11Z&se=2030-01-01T20:28:11Z&spr=https&sv=2022-11-02&sr=b&sig=TzB8nhYdMPJklM%2BMAEc0Lq2MQrEgIooTSrynJaBVzm0%3D",
        imageAlt: "Dr. Hany Farag, MD MBA - Primary Care Physician at Fanous Clinic in Charlotte, NC",
        name: "Hany Farag, MD MBA",
        pos: "Primary Care Physician",
        subPos: "Dr. Hany Farag is a skilled Primary Care Physician with expertise in preventive care and patient-centered medical services. He combines his medical knowledge with business acumen to deliver comprehensive and personalized healthcare.",
        phone: "(216) 659-5992",
        email: "hany.farag.md@fanousclinic.com",
    },
    // Adding new user Tory Grooms, PharmD
    {
        slug: "dr-tory-grooms",
        title: "Tory Grooms, PharmD - Primary Care Pharmacist at Fanous Clinic",
        description: "",
        keywords: "",
        imageUrl: "https://fanousclinicmain.blob.core.windows.net/website-images-icons/dr-tory.webp?sp=r&st=2024-11-04T10:13:53Z&se=2030-01-01T18:13:53Z&spr=https&sv=2022-11-02&sr=b&sig=oB5udKa4M5fn9JenRKa5kSyTTm4HNtugnm9TrdKYYzE%3D", // Specify the URL if you have one
        imageAlt: "Tory Grooms, PharmD - Primary Care Pharmacist at Fanous Clinic",
        name: "Tory Grooms, PharmD",
        pos: "Primary Care Pharmacist",
        subPos: "Tory is your primary care pharmacist and personal health and wellness advocate. She works alongside Dr. Farag to build long-term relationships with each patient that comes through our doors. Her priority is patient-centered wellness which means that she adapts her coaching and counseling to meet you where you are in your health journey and empower you with knowledge to help you achieve better control over your chronic conditions, reduce your symptoms, and improve your quality of life.",
        phone: "(336) 465-1441",
        email: "tory.grooms@fanousclinic.com",
    }
];
