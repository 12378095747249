import React, { useEffect } from "react";
import parse from "html-react-parser";
import { Box, Grid } from "@mui/material";
import NewsLetterForm from "../forms/NewsletterForm/NewsLetterForm.tsx";

export default function NewsletterStyle4({ title, subTitle }) {
  return (
    <div className="cs-newsletter cs-style-4 container wide">
      <div className="newsletter-row">
        <div className="newsletter-col-lg-4 newsletter-col-md-12 newsletter-col-sm-12 cs-newsletter-item">
          <h2 className="cs-newsletter-title cs-white-color">
            {parse(title)}
          </h2>
          <p className="cs-newsletter-subtitle mb-0">{parse(subTitle)}</p>
        </div>
        <div className="newsletter-col-lg-gap"></div>
        <div className="newsletter-col-lg-7 newsletter-col-md-12 newsletter-col-sm-12">
          <NewsLetterForm
            formId="newsletter-form"
            emailJsTemp={{
              en: "fcweb-en-newsletter-emp",
              es: "fcweb-es-newsletter-emp",
            }}
            darkBg
            type="newsletter"
          />
        </div>
      </div>
    </div>
  );
}
