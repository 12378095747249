"use client";

import React from "react";
import Link from "next/link";
import { useLocale, useTranslations } from "next-intl";

export default function MenuWidget({ data, links }) {
  const currentLang = useLocale();
  const AL = useTranslations("ariaLabels");

  return (
    <ul className="cs-menu-widget cs-mp0">
      {data?.map((item, index) => (
        <li key={index}>
          <Link
            href={links[index]}
            target={
              links[index] === `/${currentLang}/opt-in`
                ? "_blank"
                : "_self"
            }
            aria-label={
              links[index] === `/${currentLang}/opt-in`
                ? AL("links.opt-in")
                : "_self"
            }>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
