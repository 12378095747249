"use client";

import emailjs from "@emailjs/browser";
import { Grid, Box, Button, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { useLocale, useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import Link from "next/link";
import toast from "react-hot-toast";

interface NewsLetterFormProps {
  emailJsTemp: { en: string; es: string };
  type: string;
  formId: string;
  darkBg?: boolean;
  linkToOpen?: string;
}

const NewsLetterForm = ({
  emailJsTemp,
  darkBg,
  formId,
  type,
  linkToOpen,
}: NewsLetterFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const currentLang = useLocale();
  const t = useTranslations("forms");
  const AL = useTranslations("ariaLabels");

  const onSubmit = handleSubmit((data) => {

    emailjs
      .send(
        "service_pjujdf9",
        emailJsTemp[currentLang],
        { name: data.name, email: data.email },
        "yR82CfaRwg7YEBsWj"
      )
      .then(() => {
        setSubmitted(true);
        reset();
        if (linkToOpen) window.open(linkToOpen, "_blank");
      })
      .catch(() => {
        toast.error("Failed to send Booking request");
      });
  });

  if (submitted) {
    return (
      <div className="newsletter-container">
        <h3 className={`newsletter-success-title ${darkBg ? "text-white" : "text-dark"}`}>
          {t(`newsletterForm.success.${type}.successTitle`)}
        </h3>
        <p className={`newsletter-success-message ${darkBg ? "text-white" : "text-dark"}`}>
          {t(`newsletterForm.success.${type}.successMessage`)}
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className="cs-style-1 cs-newsletter-form" id={formId}>
      <div className="newsletter-grid-container">
        <div className="newsletter-input-container">
          <label className={`newsletter-input-label ${darkBg ? "text-white" : "text-dark"}`}>
            {t("fields.name")}
          </label>
          <input
            aria-label="Form name field"
            {...register("name", {
              required: t("validation.required"),
              pattern: { value: /^[a-zA-Z\s]+$/, message: t("validation.string") },
            })}
            className="newsletter-input-field"
            type="text"
            placeholder={t("placeholders.name")}
          />
          {errors.name && (
            <p className={`newsletter-error-message m-0 ${darkBg ? "err-dark-bg" : "err-light-bg"}`}>
              {errors.name.message?.toString()}
            </p>
          )}
        </div>

        <div className="newsletter-input-container">
          <label className={`newsletter-input-label ${darkBg ? "text-white" : "text-dark"}`}>
            {t("fields.email")}
          </label>
          <input
            aria-label="Form email field"
            {...register("email", {
              required: t("validation.required"),
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: t("validation.notValidEmail"),
              },
            })}
            className="newsletter-input-field"
            type="email"
            placeholder={t("placeholders.email")}
          />
          {errors.email && (
            <p className={`newsletter-error-message m-0 ${darkBg ? "err-dark-bg" : "err-light-bg"}`}>
              {errors.email.message?.toString()}
            </p>
          )}
        </div>

        <div className="newsletter-submit-container">
          <button
            type="submit"
            id={`${formId}-btn`}
            className={`newsletter-submit-btn ${darkBg ? "newsletter-bg-dark" : "newsletter-bg-white"}`}
          >
            {t(`newsletterForm.btnText.${type}`)}
          </button>
        </div>

        <div className="newsletter-checkbox-container">
          <input
            aria-label="Policies and Conditions confirmation"
            {...register("checkbox", { required: t("validation.required") })}
            id={`${formId}-acceptance-checkbox`}
            className="checkbox"
            type="checkbox"
          />
          <label htmlFor="acceptance-checkbox" className={`newsletter-terms ${darkBg ? "text-white" : "text-dark"}`}>
            {t("newsletterForm.p1")}
            <Link href={`/${currentLang}/privacy-policy`} target="_blank" className="link-style">
              {t("newsletterForm.l1")}
            </Link>
            {t("newsletterForm.p2")}
            <Link href={`/${currentLang}/terms-conditions`} target="_blank" className="link-style">
              {t("newsletterForm.l2")}
            </Link>
          </label>
          {errors.checkbox && (
            <p className={`checkbox-error-message m-0 ${darkBg ? "err-dark-bg" : "err-light-bg"}`}>
              {errors.checkbox.message?.toString() || "An error occurred"}
            </p>
          )}
        </div>
      </div>
    </form>

  );

};

export default NewsLetterForm;
