"use client";

import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import upArrowSrc from "../../../../public/images/icons/up_arrow.svg";
import { useTranslations } from "next-intl";
import Image from "next/image";

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

const ArrowToUp = () => {
  const AL = useTranslations("ariaLabels");
  const [visible, setVisible] = useState(false);

  // Use useCallback to memoize the toggleVisibility function
  const toggleVisibility = useCallback(() => {
    if (window.pageYOffset > 50) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const debouncedToggleVisibility = debounce(toggleVisibility, 100); // Adding 100ms debounce delay
    window.addEventListener("scroll", debouncedToggleVisibility);
    return () => {
      window.removeEventListener("scroll", debouncedToggleVisibility);
    };
  }, [toggleVisibility]);

  return (
    <div
      role="button"
      onClick={scrollToTop}
      aria-label={AL("buttons.arrowToTop")}
      tabIndex={0}
      className={`scroll-to-top ${visible ? "visible" : ""}`}>
      <Image
        src={upArrowSrc}
        alt={AL("buttons.arrowToTop")}
        width={24}
        height={40}
      />
    </div>
  );
};

export default ArrowToUp;
