"use client"
import React from 'react'

const SkipToMain = () => {
    return (
        <a href="#hero" className="skip-link">
            <style jsx>
                {`
        .skip-link {
          position: absolute;
          top: 0;
          opacity:0;
          right: 100%; /* moves off screen */
          z-index: 1500; /* some number that will make sure it covers other elements */
        }

        .skip-link:focus {
          right: auto;
          background-color: #033449;
          color: #fff;
          border: #fff 3px solid;
          padding: 1em;
           opacity:1;
        }
      `}
            </style>
            Skip to content
        </a>
    )
}

export default SkipToMain