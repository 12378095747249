import { Icon } from "@iconify/react";
import Link from "next/link";
import React from "react";
import FooterData from "../Footer/FooterData";
import { useTranslations } from "next-intl";

export default function SocialWidget() {
  const t = useTranslations("footer");
  return (
    <div className="cs-social-links-wrap">
      <h2>{t("socialTitle")}</h2>
      <div className="cs-social-links">
        <Link
          href={FooterData.SocialMedia.Facebook}
          target="_blank"
          aria-label="Join us at facebook">
          <Icon icon="fa-brands:facebook-f" />
        </Link>
        <Link
          href={FooterData.SocialMedia.Instagram}
          target="_blank"
          aria-label="Join us at instagram">
          <Icon icon="fa-brands:instagram" />
        </Link>
        <Link
          href={FooterData.SocialMedia.Linkedin}
          target="_blank"
          aria-label="Join us at linkedin">
          <Icon icon="fa-brands:linkedin-in" />
        </Link>
        <Link
          href={FooterData.SocialMedia.x}
          target="_blank"
          aria-label="Join us at X">
          <Icon icon="pajamas:twitter" />
        </Link>

      </div>
    </div>
  );
}
